import {
    allFam, ark, assetsPath, beach, brian, celeste, celyna,
    corns, emily, kidsMom, mainKids, megan, newman,
    nO, ray, tim, york
} from './constants';

export default [{
    title: 'Tim\'s Birthday',
    year: '90',
    keywords: [],
    star: [tim],
    location: [nO],
    url: `${assetsPath}/90/tim-birthday-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/tim-birthday-90.jpg`,
}, {
    title: 'Tim\'s Halloween',
    year: '90',
    keywords: [],
    star: [tim],
    location: newman,
    url: `${assetsPath}/90/tim-costume-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/tim-costume-90.jpg`,
}, {
    title: 'Halloween',
    year: '90',
    keywords: [],
    star: kidsMom,
    location: newman,
    url: `${assetsPath}/90/halloween-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/halloween-90.jpg`,
}, {
    title: 'Christmas',
    year: '90',
    keywords: [],
    star: kidsMom,
    location: york,
    url: `${assetsPath}/90/christmas-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/christmas-90.jpg`,
}, {
    title: 'Brian Playing Basketball',
    year: '90',
    keywords: [],
    star: [brian],
    location: [nO],
    url: `${assetsPath}/90/brian-basketball-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/brian-basketball-90.jpg`,
}, {
    title: 'Playing',
    year: '90',
    keywords: [],
    star: [...kidsMom, celyna, megan],
    location: ark,
    url: `${assetsPath}/90/arkansas-playing-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/arkansas-playing-90.jpg`,
}, {
    title: 'Christmas',
    year: '90',
    keywords: [],
    star: [...kidsMom, ...corns],
    location: ark,
    url: `${assetsPath}/90/arkansas-christmas-90.mp4`,
    thumbnailUrl: `${assetsPath}/90/thumbnails/arkansas-christmas-90.jpg`,
}, {
    title: 'Tim\'s Birthday',
    year: '91',
    keywords: [],
    star: kidsMom,
    location: york,
    url: `${assetsPath}/91/tim-birthday-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/tim-birthday-91.jpg`,
}, {
    title: 'Dance Recital',
    year: '91',
    keywords: [],
    star: [emily, tim],
    location: [nO],
    url: `${assetsPath}/91/emily-dance-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/emily-dance-91.jpg`,
}, {
    title: 'Dancing',
    year: '91',
    keywords: [],
    star: kidsMom,
    location: york,
    url: `${assetsPath}/91/dancing-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/dancing-91.jpg`,
}, {
    title: 'Cousins Playing',
    year: '91',
    keywords: [],
    star: [emily, celyna, tim, celeste, megan],
    location: ark,
    url: `${assetsPath}/91/cousins-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/cousins-91.jpg`,
}, {
    title: 'Brian\'s Playing',
    year: '91',
    keywords: [],
    star: [brian],
    location: [nO],
    url: `${assetsPath}/91/brian-play-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/brian-play-91.jpg`,
}, {
    title: 'Boxing',
    year: '91',
    keywords: [],
    star: [ray, ...mainKids],
    location: york,
    url: `${assetsPath}/91/boxing-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/boxing-91.jpg`,
}, {
    title: 'Beach',
    year: '91',
    keywords: [],
    star: allFam,
    location: beach,
    url: `${assetsPath}/91/beach-91.mp4`,
    thumbnailUrl: `${assetsPath}/91/thumbnails/beach-91.jpg`,
}]