import { assetsPath, brian, church, emily, mainKids, miro, newman, nO, tim } from "./constants";

export default [{
    title: 'Tim Soccer',
    year: '96',
    keywords: [],
    star: [tim],
    location: [nO],
    url: `${assetsPath}/96/tim-soccer-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/tim-soccer-96.jpg`,
}, {
    title: 'Tim Soccer 2',
    year: '96',
    keywords: [],
    star: [tim],
    location: [nO],
    url: `${assetsPath}/96/tim-soccer_2-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/tim-soccer_2-96.jpg`,
}, {
    title: 'Tim Play',
    year: '96',
    keywords: [],
    star: [tim],
    location: newman,
    url: `${assetsPath}/96/tim-play-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/tim-play-96.jpg`,
}, {
    title: 'Tim Class',
    year: '96',
    keywords: [],
    star: [tim],
    location: newman,
    url: `${assetsPath}/96/tim-class-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/tim-class-96.jpg`,
}, {
    title: 'Tim\'s Class Party',
    year: '96',
    keywords: [],
    star: [tim],
    location: newman,
    url: `${assetsPath}/96/tim-class_party-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/tim-class_party-96.jpg`,
}, {
    title: 'Tim\'s Birthday',
    year: '96',
    keywords: [],
    star: [tim],
    location: ['orleans'],
    url: `${assetsPath}/96/tim-birthday_2-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/tim-birthday_2-96.jpg`,
}, {
    title: 'Mardi Gras',
    year: '96',
    keywords: [],
    star: mainKids,
    location: [nO],
    url: `${assetsPath}/96/mardi_gras-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/mardi_gras-96.jpg`,
}, {
    title: 'Emily\'s Report on Slavery',
    year: '96',
    keywords: [],
    star: [emily],
    location: newman,
    url: `${assetsPath}/96/emily-slavery-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/emily-slavery-96.jpg`,
}, {
    title: 'Emily\'s Skit',
    year: '96',
    keywords: [],
    star: [emily],
    location: newman,
    url: `${assetsPath}/96/emily-skit-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/emily-skit-96.jpg`,
}, {
    title: 'Emily\'s Play',
    year: '96',
    keywords: [],
    star: [emily],
    location: newman,
    url: `${assetsPath}/96/emily-play-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/emily-play-96.jpg`,
}, {
    title: 'Emily\'s Play 2',
    year: '96',
    keywords: [],
    star: [emily],
    location: newman,
    url: `${assetsPath}/96/emily-play_2-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/emily-play_2-96.jpg`,
}, {
    title: 'Church Play',
    year: '96',
    keywords: [],
    star: mainKids,
    location: church,
    url: `${assetsPath}/96/church_play-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/church_play-96.jpg`,
}, {
    title: 'Christmas Eve',
    year: '96',
    keywords: [],
    star: mainKids,
    location: miro,
    url: `${assetsPath}/96/christmas_eve-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/christmas_eve-96.jpg`,
}, {
    title: 'Christmas Day',
    year: '96',
    keywords: [],
    star: mainKids,
    location: miro,
    url: `${assetsPath}/96/christmas_day-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/christmas_day-96.jpg`,
}, {
    title: 'Brian Playing Football',
    year: '96',
    keywords: [],
    star: [brian],
    location: newman,
    url: `${assetsPath}/96/brian-football-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/brian-football-96.jpg`,
}, {
    title: 'Beaux!',
    year: '96',
    keywords: [],
    star: [tim, emily],
    location: miro,
    url: `${assetsPath}/96/beaux-96.mp4`,
    thumbnailUrl: `${assetsPath}/96/thumbnails/beaux-96.jpg`,
}]