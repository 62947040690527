import {
    allFam,
    ark,
    assetsPath,
    brums,
    brumsAuntsCousins,
    celyna,
    corns,
    disney,
    jean,
    kidsMom,
    nO,
    york
} from './constants';

export default [{
    title: 'Disney',
    year: '87',
    keywords: [],
    star: kidsMom,
    location: disney,
    url: `${assetsPath}/87/disney-87.mp4`,
    thumbnailUrl: `${assetsPath}/87/thumbnails/disney-87.jpg`,
}, {
    title: 'Emily\'s Birth',
    year: '87',
    keywords: [],
    star: kidsMom,
    location: [nO],
    url: `${assetsPath}/87/emily-birth-87.mp4`,
    thumbnailUrl: `${assetsPath}/87/thumbnails/emily-birth-87.jpg`,
}, {
    title: 'Christmas',
    year: '89',
    keywords: [],
    star: [...kidsMom, ...corns, ...brums],
    location: ark,
    url: `${assetsPath}/89/arkansas-christmas-89.mp4`,
    thumbnailUrl: `${assetsPath}/89/thumbnails/arkansas-christmas-89.jpg`,
}, {
    title: 'Sledding',
    year: '89',
    keywords: [],
    star: [...allFam, ...corns, celyna],
    location: ark,
    url: `${assetsPath}/89/arkansas-sled-89.mp4`,
    thumbnailUrl: `${assetsPath}/89/thumbnails/arkansas-sled-89.jpg`,
}, {
    title: 'Tim\'s Birthday',
    year: '89',
    keywords: [],
    star: [...allFam, ...brumsAuntsCousins, jean],
    location: [...ark, nO],
    url: `${assetsPath}/89/brian-birthday-89.mp4`,
    thumbnailUrl: `${assetsPath}/89/thumbnails/brian-birthday-89.jpg`,
}, {
    title: 'Christmas',
    year: '89',
    keywords: [],
    star: kidsMom,
    location: york,
    url: `${assetsPath}/89/christmas-89.mp4`,
    thumbnailUrl: `${assetsPath}/89/thumbnails/christmas-89.jpg`,
}, {
    title: 'Emily\'s Birthday',
    year: '89',
    keywords: [],
    star: kidsMom,
    location: york,
    url: `${assetsPath}/89/emily-birthday-89.mp4`,
    thumbnailUrl: `${assetsPath}/89/thumbnails/emily-birthday-89.jpg`,
}, {
    title: 'Fall',
    year: '89',
    keywords: [],
    star: kidsMom,
    location: [...ark, nO],
    url: `${assetsPath}/89/fall-89.mp4`,
    thumbnailUrl: `${assetsPath}/89/thumbnails/fall-89.jpg`,
}];
