export const assetsPath = 'https://assets.corneliuses.com/videos/family/Home-movies';

export const brian = "brian";
export const ray = "ray";
export const gloria = "gloria";
export const emily = "emily";
export const tim = "tim";
export const celyna = "celyna";
export const celeste = "celeste";
export const clarice = "clarice";
export const molly = "molly";
export const megan = "megan";
export const jane = "jane";
export const jean = "jean";
export const berta = "berta";
export const glenda = "glenda";
export const gary = "gary";
export const garrett = "garrett";
export const mainKids = [tim, brian, emily];
export const kidsMom = [...mainKids, gloria]
export const allFam = [...kidsMom, ray]
export const sigs = [celeste, celyna, clarice]
export const brumsAuntsCousins = [molly, megan, ...sigs];
export const brums = [...brumsAuntsCousins, jane];
export const brumsWithFam = [...brumsAuntsCousins, ...kidsMom];
export const cornsAuntsCousins = [glenda, berta, gary, garrett];
export const corns = [...cornsAuntsCousins, jean]

export const nO = 'orleans';
export const ark = ['arkansas'];
export const newman = [nO, 'newman'];
export const york = [nO, 'york'];
export const miro = [nO, 'miro'];
export const church = [nO, 'church'];
export const beach = ['beach'];
export const disney = ['disney'];
