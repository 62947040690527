import {assetsPath, clarice, gloria, jane, molly} from './constants';

export default [{
    title: 'Guam',
    year: '55',
    keywords: [],
    star: [],
    location: ['guam'],
    url: `${assetsPath}/55/guam-55.mp4`,
    thumbnailUrl: `${assetsPath}/55/thumbnails/guam-55.jpg`,
}, {
    title: 'Boats',
    year: '56',
    keywords: [],
    star: [gloria],
    location: [],
    url: `${assetsPath}/56/mom-boats-56.mp4`,
    thumbnailUrl: `${assetsPath}/56/thumbnails/mom-boats-56.jpg`,
}, {
    title: 'Colorado',
    year: '56',
    keywords: [],
    star: [gloria],
    location: ['colorado'],
    url: `${assetsPath}/56/mom-colorado-56.mp4`,
    thumbnailUrl: `${assetsPath}/56/thumbnails/mom-colorado-56.jpg`,
}, {
    title: 'House party',
    year: '56',
    keywords: [],
    star: [gloria, jane],
    location: [],
    url: `${assetsPath}/56/mom-party-56.mp4`,
    thumbnailUrl: `${assetsPath}/56/thumbnails/mom-party-56.jpg`,
}, {
    title: 'February',
    year: '56',
    keywords: [],
    star: [gloria, jane],
    location: [],
    url: `${assetsPath}/56/february-56.mp4`,
    thumbnailUrl: `${assetsPath}/56/thumbnails/february-56.jpg`,
}, {
    title: 'Christmas',
    year: '57',
    keywords: [],
    star: [gloria, jane],
    location: [],
    url: `${assetsPath}/57/christmas-57.mp4`,
    thumbnailUrl: `${assetsPath}/57/thumbnails/christmas-57.jpg`,
}, {
    title: 'Horses',
    year: '57',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/57/mom-horses-57.mp4`,
    thumbnailUrl: `${assetsPath}/57/thumbnails/mom-horses-57.jpg`,
}, {
    title: 'Parade',
    year: '57',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/57/mom-parade-57.mp4`,
    thumbnailUrl: `${assetsPath}/57/thumbnails/mom-parade-57.jpg`,
}, {
    title: 'Party',
    year: '57',
    keywords: [],
    star: [gloria, jane],
    location: [],
    url: `${assetsPath}/57/mom-party-57.mp4`,
    thumbnailUrl: `${assetsPath}/57/thumbnails/mom-party-57.jpg`,
}, {
    title: 'Playing',
    year: '57',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/57/playing-57.mp4`,
    thumbnailUrl: `${assetsPath}/57/thumbnails/playing-57.jpg`,
}, {
    title: 'Bath',
    year: '58',
    keywords: [],
    star: [gloria, molly, clarice],
    location: [],
    url: `${assetsPath}/58/bath-58.mp4`,
    thumbnailUrl: `${assetsPath}/58/thumbnails/bath-58.jpg`,
}, {
    title: 'Beach',
    year: '58',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/58/beach-58.mp4`,
    thumbnailUrl: `${assetsPath}/58/thumbnails/beach-58.jpg`,
}, {
    title: 'Easter',
    year: '58',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/58/easter-58.mp4`,
    thumbnailUrl: `${assetsPath}/58/thumbnails/easter-58.jpg`,
}, {
    title: 'Storyland',
    year: '58',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/58/storyland-58.mp4`,
    thumbnailUrl: `${assetsPath}/58/thumbnails/storyland-58.jpg`,
}, {
    title: 'Madrid',
    year: '59',
    keywords: [],
    star: [],
    location: [],
    url: `${assetsPath}/59/madrid-january-59.mp4`,
    thumbnailUrl: `${assetsPath}/59/thumbnails/madrid-january-59.jpg`,
}];
