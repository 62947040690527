import {
    allFam, ark, assetsPath, beach, brian, disney, emily, gloria,
    jean, kidsMom, mainKids, miro, nO, sigs, tim
} from "./constants";

export default [{
    title: 'Figment Ride',
    year: '94',
    keywords: [],
    star: kidsMom,
    location: disney,
    url: `${assetsPath}/94/figment-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/figment-94.jpg`,
}, {
    title: 'First Day of School',
    year: '94',
    keywords: [],
    star: kidsMom,
    location: miro,
    url: `${assetsPath}/94/first_day-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/first_day-94.jpg`,
}, {
    title: 'Magic Kingdom',
    year: '94',
    keywords: [],
    star: kidsMom,
    location: disney,
    url: `${assetsPath}/94/magic_kingdom-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/magic_kingdom-94.jpg`,
}, {
    title: 'Day at the Pool',
    year: '94',
    keywords: [],
    star: kidsMom,
    location: beach,
    url: `${assetsPath}/94/pool-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/pool-94.jpg`,
}, {
    title: 'Tim Dancing',
    year: '94',
    keywords: [],
    star: [tim, brian],
    location: miro,
    url: `${assetsPath}/94/tim-dancing-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/tim-dancing-94.jpg`,
}, {
    title: 'Tim\'s Last Day',
    year: '94',
    keywords: [],
    star: [tim],
    location: [nO],
    url: `${assetsPath}/94/tim-last_day-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/tim-last_day-94.jpg`,
}, {
    title: 'Tim at School',
    year: '94',
    keywords: [],
    star: [tim, gloria],
    location: [nO],
    url: `${assetsPath}/94/tim-school-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/tim-school-94.jpg`,
}, {
    title: 'Universal Horror',
    year: '94',
    keywords: [],
    star: [],
    location: disney,
    url: `${assetsPath}/94/universal-horror-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/universal-horror-94.jpg`,
}, {
    title: 'Universal Studios',
    year: '94',
    keywords: [],
    star: kidsMom,
    location: disney,
    url: `${assetsPath}/94/universal-94.mp4`,
    thumbnailUrl: `${assetsPath}/94/thumbnails/universal-94.jpg`,
}, {
    title: 'Tim\'s Play',
    year: '95',
    keywords: [],
    star: [tim],
    location: [nO],
    url: `${assetsPath}/95/tim-play-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/tim-play-95.jpg`,
}, {
    title: 'Tim\'s Birthday',
    year: '95',
    keywords: [],
    star: kidsMom,
    location: [nO],
    url: `${assetsPath}/95/tim-birthday-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/tim-birthday-95.jpg`,
}, {
    title: 'Softball',
    year: '95',
    keywords: [],
    star: [emily],
    location: [nO],
    url: `${assetsPath}/95/softball-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/softball-95.jpg`,
}, {
    title: 'May Flood',
    year: '95',
    keywords: [],
    star: [],
    location: [nO],
    url: `${assetsPath}/95/mayflood-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/mayflood-95.jpg`,
}, {
    title: 'Ho Down',
    year: '95',
    keywords: [],
    star: [brian],
    location: [nO],
    url: `${assetsPath}/95/hodown-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/hodown-95.jpg`,
}, {
    title: 'Graduation',
    year: '95',
    keywords: [],
    star: [brian],
    location: [nO],
    url: `${assetsPath}/95/graduation-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/graduation-95.jpg`,
}, {
    title: 'Class Halloween',
    year: '95',
    keywords: [],
    star: [emily],
    location: [nO],
    url: `${assetsPath}/95/emily-halloween-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/emily-halloween-95.jpg`,
}, {
    title: 'Dance Recital',
    year: '95',
    keywords: [],
    star: [emily],
    location: [nO],
    url: `${assetsPath}/95/emily-dance-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/emily-dance-95.jpg`,
}, {
    title: 'Emily\'s Birthday',
    year: '95',
    keywords: [],
    star: [emily],
    location: miro,
    url: `${assetsPath}/95/emily-birthday-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/emily-birthday-95.jpg`,
}, {
    title: 'Easter',
    year: '95',
    keywords: [],
    star: allFam,
    location: miro,
    url: `${assetsPath}/95/easter-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/easter-95.jpg`,
}, {
    title: 'Christmas',
    year: '95',
    keywords: [],
    star: mainKids,
    location: miro,
    url: `${assetsPath}/95/christmas-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/christmas-95.jpg`,
}, {
    title: 'Brian\'s Birthday',
    year: '95',
    keywords: [],
    star: [brian, tim],
    location: miro,
    url: `${assetsPath}/95/birthday-brian-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/birthday-brian-95.jpg`,
}, {
    title: 'After the Beach',
    year: '95',
    keywords: [],
    star: mainKids,
    location: beach,
    url: `${assetsPath}/95/beach_02-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/beach_02-95.jpg`,
}, {
    title: 'At the Beach',
    year: '95',
    keywords: [],
    star: kidsMom,
    location: beach,
    url: `${assetsPath}/95/beach_01-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/beach_01-95.jpg`,
}, {
    title: 'Tim\'s Birthday',
    year: '95',
    keywords: [],
    star: [...kidsMom, ...sigs, jean],
    location: ark,
    url: `${assetsPath}/95/arkansas-new-years-95.mp4`,
    thumbnailUrl: `${assetsPath}/95/thumbnails/arkansas-new-years-95.jpg`,
}]